import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '../shared-services/configuration.service';
import { AuthenticationService } from 'src/shared-services/authentication.service';
import { PrimeNGConfig } from 'primeng/api';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	title = 'angular';

	constructor(
    private translate: TranslateService,
    public configurationService: ConfigurationService,
    private authenticationService: AuthenticationService,
    private config: PrimeNGConfig
	) {
		// this language will be used as a fallback when a translation isn't found in the current language
		this.translate.setDefaultLang('es');

		// add app languages
		this.translate.addLangs(['en', 'es']);

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		this.translate.use('es');

		// PrimeNG Configuration
		this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));

		this.authenticationService.currentUser.subscribe(user => {
			if (user) {
				this.configurationService.getConfiguration();
			}
		});


	}
}