import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { TagModule } from 'primeng/tag';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabViewModule } from 'primeng/tabview';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';

// Todas lo relacionado con PrimeNg debe ir en este modulo.
@NgModule({
	declarations: [],
	providers: [
		ConfirmationService
	],
	imports: [
		CommonModule,
		CardModule,
		InputTextModule,
		PasswordModule,
		ButtonModule,
		MenubarModule,
		ProgressSpinnerModule,
		CalendarModule,
		ButtonModule,
		TableModule,
		SkeletonModule,
		DropdownModule,
		SidebarModule,
		RadioButtonModule,
		InputGroupModule,
		InputGroupAddonModule,
		DialogModule,
		TooltipModule,
		OverlayPanelModule,
		IconFieldModule,
		InputIconModule,
		ConfirmDialogModule,
		ToastModule,
		AccordionModule,
		InputNumberModule,
		TabViewModule,
		InputTextareaModule,
		CheckboxModule,
		InputNumberModule
	],
	exports: [
		CommonModule,
		CardModule,
		InputTextModule,
		PasswordModule,
		ButtonModule,
		MenubarModule,
		ProgressSpinnerModule,
		CalendarModule,
		ButtonModule,
		TableModule,
		SkeletonModule,
		DropdownModule,
		SidebarModule,
		RadioButtonModule,
		InputGroupModule,
		InputGroupAddonModule,
		DialogModule,
		TooltipModule,
		OverlayPanelModule,
		IconFieldModule,
		InputIconModule,
		ConfirmDialogModule,
		ToastModule,
		AccordionModule,
		TagModule,
		InputNumberModule,
		TabViewModule,
		InputTextareaModule,
		CheckboxModule,
		InputNumberModule
	]
})
export class PrimengModule { }