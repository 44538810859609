<p-table [value]="adminFeasibilityService.feasibilityRules" [loading]="adminFeasibilityService.isLoading"
    [paginator]="true" [(rows)]="adminFeasibilityService.rowsPerPage"
    [rowsPerPageOptions]="adminFeasibilityService.rowsPerPageOptions"
    [totalRecords]="adminFeasibilityService.feasibilityRules.length">
    <ng-template pTemplate="caption">
        <button class="outline-btn" (click)="adminFeasibilityService.fetchFeasibilityRules(true)">
            <span class="material-symbols-outlined ico-list"> sync </span>

            <div style="display: flex; flex-direction: column; padding-right: 10px;">
                {{ "MY_REQUESTS.SYNC" | translate }}
                <span style="font-size: xx-small;">{{ "MY_REQUESTS.LAST_TIME_SYNC" | translate}}
                    {{adminFeasibilityService.lastSyncDate | date:
                    "HH:mm"}}</span>
            </div>
        </button>

        <button class="filled-btn" (click)="createFeasibilityRule()">
            <span class="material-symbols-outlined ico-list"> add_circle </span>
            {{ "ADMINISTRATION.NEW_RULE" | translate }}
        </button>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>{{ 'ADMINISTRATION.FEASIBILITY.CONCEPT' | translate }}</th>
            <th>{{ 'ADMINISTRATION.FEASIBILITY.DESCRIPTION' | translate }}</th>
            <th>{{ 'ADMINISTRATION.FEASIBILITY.FIRST_FIELD' | translate }}</th>
            <th>{{ 'ADMINISTRATION.FEASIBILITY.SECOND_FIELD' | translate }}</th>
            <th>{{ 'ADMINISTRATION.FEASIBILITY.OPERATOR' | translate }}</th>
            <th>{{ 'ADMINISTRATION.FEASIBILITY.VALUE' | translate }}</th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rule let-i="rowIndex">
        <tr>
            <td>
                <span class="material-symbols-outlined">
                    {{ rule.active === true ? 'check_small' : 'cancel' }}
                </span>
            </td>
            <td>{{ rule.ruleConcept }}</td>
            <td>{{ rule.ruleDescription }}</td>
            <td>{{ rule.fieldName }}</td>
            <td>{{ rule.fieldName2 }}</td>
            <td>{{ translateOperator(rule.operator) }}</td>
            <td>{{ rule.value }}</td>
            <td>
                <button type="button" class="btn-table" (click)="updateFeasibilityRule(i)"
                    [pTooltip]="'ADMINISTRATION.MODIFY' | translate" tooltipPosition="left">
                    <span class="material-symbols-outlined"> edit </span>
                </button>
            </td>
            <td>
                <button type="button" class="btn-table" (click)="confrimDeleteFeasibilityRule($event, i)"
                    [pTooltip]="'ADMINISTRATION.DELETE' | translate" tooltipPosition="left">
                    <span class="material-symbols-outlined"> delete </span>
                </button>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
        <tr *ngFor="let row of utilsService.range(10)">
            <td *ngFor="let column of utilsService.range(9)">
                <p-skeleton />
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        {{ 'primeng.emptyMessage' | translate }}
    </ng-template>
</p-table>

@if(adminFeasibilityService.isSidebarVisible){
<p-sidebar [(visible)]="adminFeasibilityService.isSidebarVisible" position="right" styleClass="W700" dismissible="false"
    closeOnEscape="false">
    <app-manage-admin-feasibility />
</p-sidebar>
}