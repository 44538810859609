import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyRequestsComponent } from './my-requests.component';
import { UsualModule } from 'src/app/usual.module';
import { UtilsModule } from 'src/app/utils/utils.module';
import { NewRequestModule } from '../new-request/new-request.module';
import { RequestsActiveFiltersComponent } from './requests-active-filters/requests-active-filters.component';
import { StatusCounterComponent } from './status-counter/status-counter.component';



@NgModule({
	declarations: [
		MyRequestsComponent,
		RequestsActiveFiltersComponent,
		StatusCounterComponent
	],
	imports: [
		CommonModule,
		UsualModule,
		UtilsModule,
		NewRequestModule
	],
	exports: [
		MyRequestsComponent
	]
})
export class MyRequestsModule { }
