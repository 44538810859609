import { Injectable } from '@angular/core';
import { ApiService } from 'src/shared-services/api.service';
import { AuthenticationService } from 'src/shared-services/authentication.service';
import { User } from '../models/User';
import { ConfigurationService } from '../../shared-services/configuration.service';

@Injectable({
	providedIn: 'root'
})

export class LoginService {

	showLoginDialog = false;

	constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService
	) { }

	async login(loginName: string, password: string) {
		const user = await this.apiService.post<User>("users/authenticate", { loginName, password });
		sessionStorage.setItem('currentUser', JSON.stringify(user));

		this.authenticationService.setPswd(password);
		this.authenticationService.currentUserSubject.next(user);
		this.configurationService.setConfiguration(user.appConfiguration);
		this.configurationService.setStyle();
	}

	logout() {
		// remove user from local storage to log user out
		sessionStorage.removeItem('currentUser');
		this.configurationService.setDefaultStyle();
		this.authenticationService.setPswd(null);
		this.authenticationService.currentUserSubject.next(null);
	}
}