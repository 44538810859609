import { Component, Input, OnDestroy } from '@angular/core';
import { RequestDetailFeasibilityService } from './request-detail-feasibility.service';
import { RequestDetailService } from '../request-detail.service';
import { Observable, Subscription } from 'rxjs';
import { FeasibilityRule } from 'src/app/models/FeasibilityRule';
import { ToastService } from 'src/shared-services/toast.service';
import { NewFeasibilityStatus } from 'src/app/models/NewFeasibilityStatus';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { RequestDetailDocumentsService } from '../request-detail-documents/request-detail-documents.service';

@Component({
	selector: 'app-request-detail-feasibility',
	templateUrl: './request-detail-feasibility.component.html',
	styleUrl: './request-detail-feasibility.component.scss'
})
export class RequestDetailFeasibilityComponent implements OnDestroy {

	private rulesSubscription: Subscription | any;
	@Input() public newFeasibilityStatus: NewFeasibilityStatus = { title: 'New feasibility', step: 1 };
	readonly OBRules = ['CodigoReglaOB1', 'CodigoReglaOB2'];

	constructor(
		private requestDetailService: RequestDetailService,
		private requestDetailDocumentsService: RequestDetailDocumentsService,
		public requestDetailFeasibilityService: RequestDetailFeasibilityService,
		private toastService: ToastService,
		private translateService: TranslateService,
		private confirmationService: ConfirmationService) {
	}

	ngOnDestroy() {
		if (this.rulesSubscription) {
			this.rulesSubscription.unsubscribe();
		}
	}

	submit() {
		if (this.requestDetailFeasibilityService.feasibilityForm.invalid) {
			this.toastService.showError('TOAST.ERROR', 'REQUEST_DETAIL.SOME_FIELDS_REQUIRED', true);
			return;
		}

		const requestMap = this.createRequestMap();
		this.rulesSubscription = this.requestDetailFeasibilityService.getRules().subscribe(rules => {
			/*if (!this.doRulesMatchMapFields(rules, requestMap)) {
		this.toastService.showError('TOAST.ERROR', 'REQUEST_DETAIL.RULES_NOT_MATCH', true);
	  } else {*/
			this.requestDetailFeasibilityService.isFeasible = this.evaluateRules(rules, requestMap);
			//}
		});

		this.newFeasibilityStatus = { title: 'Feasibility result', step: 2 };
	}

	goToStepOne() {
		this.newFeasibilityStatus = { title: 'New feasibility', step: 1 };
		//this.requestDetailFeasibilityService.resetFeasibility();
	}

	goToStepTwo() {
		this.newFeasibilityStatus = { title: 'Feasibility result', step: 2 };
		//this.requestDetailFeasibilityService.resetFeasibility();
	}

	goToAcceptOrDenied() {
		const odViabilidad = this.requestDetailDocumentsService.enrichmentAccordion?.find(el => el.title === 'OD_campos_viabilidad');
		const odIngresosValue = odViabilidad?.elements?.find(el => el.key === 'OD_Ingresos').value;
		const credit = Number(this.requestDetailService.request?.enrichment?.['100_2022_1A']?.['OD_Ingresos'] ?? odIngresosValue ?? 0);
		this.requestDetailFeasibilityService.calculateCreditAndLimits(credit);
		this.newFeasibilityStatus = { title: 'Feasibility status', step: 3 };
	}

	async completeOperation() {
		this.confirmationService.confirm({
			message: this.translateService.instant('ADMINISTRATION.FEASIBILITY.CONFIRMATION_MESSAGE'),
			acceptLabel: this.translateService.instant('BUTTON.YES'),
			rejectLabel: this.translateService.instant('BUTTON.NO'),
			accept: () => {
				if (this.requestDetailFeasibilityService.isFeasible) {
					this.requestDetailFeasibilityService.grantCredit();
				}
				else {
					this.requestDetailFeasibilityService.denyCredit();
				}
			}
		});
	}

	private createRequestMap(): Map<string, any> {
		// TEMPORAL: Obtener valores de los campos de la sección de viabilidad
		const odViabilidad = this.requestDetailDocumentsService.enrichmentAccordion?.find(el => el.title === 'OD_campos_viabilidad');
		const facturacionValue = odViabilidad?.elements?.find(el => el.key === 'Facturacion').value;
		const odNumAnoEmpActualValue = odViabilidad?.elements?.find(el => el.key === 'OD_NUMANOEMPACTUAL').value;
		const odIngresosValue = odViabilidad?.elements?.find(el => el.key === 'OD_Ingresos').value;
		const D4AValue = odViabilidad?.elements?.find(el => el.key === 'D4A').value;

		// Asignar los valores con manejo de propiedades opcionales y valores por defecto
		const facturacion = this.requestDetailService.request?.enrichment?.['100_2022_1A']?.['Importe medio'] ?? facturacionValue ?? 0;
		const odNumAnoEmpActual = this.requestDetailService.request?.enrichment?.['Laboral']?.['OD_NUMANOEMPACTUAL'] ?? odNumAnoEmpActualValue ?? 0;
		const odIngresos = this.requestDetailService.request?.enrichment?.['100_2022_1A']?.['OD_Ingresos'] ?? odIngresosValue ?? 0;
		// const ingresosElegibles = this.requestDetailService.request?.enrichment?.['100_2022_1A']?.['Ingresos Elegibles'] ?? 0;
		// const gastosComprometidos = this.requestDetailService.request?.enrichment?.['100_2022_1A']?.['Gastos Comrometidos'] ?? 0;
		const D4A = this.requestDetailService.request?.enrichment?.['100_2022_1A']?.['D4A'] ?? D4AValue ?? 'D';

		return new Map([
			/* CAMPOS PERSONQUERY */
			["id", this.requestDetailService.request.personQuery.personId],
			["personType", this.requestDetailService.request.personQuery.personType],

			/* CAMPOS ADICIONALES CALCULADOS */
			["Facturacion", facturacion],
			["OD_NumAnoEmpActual", odNumAnoEmpActual],
			["OD_Ingresos", odIngresos],
			// ["Ingresos Elegibles", ingresosElegibles],
			// ["Gastos Comrometidos", gastosComprometidos],
			["D4A", D4A],

			/* CAMPOS FORMULARIO */
			["IngresosElegibles", this.requestDetailFeasibilityService.feasibilityForm.get('IngresosElegibles').value],
			["GastosComprometidos", this.requestDetailFeasibilityService.feasibilityForm.get('GastosComprometidos').value],
			["ScoreOpenBanking", this.requestDetailFeasibilityService.feasibilityForm.get('ScoreOpenBanking').value],
			["CodigoReglaOB1", this.requestDetailFeasibilityService.feasibilityForm.get('CodigoReglaOB1').value],
			["CodigoReglaOB2", this.requestDetailFeasibilityService.feasibilityForm.get('CodigoReglaOB2').value],
			["ImporteDeudaTotalCirculanteCIRBE", this.requestDetailFeasibilityService.feasibilityForm.get('ImporteDeudaTotalCirculanteCIRBE').value],
			["ImporteMoraCIRBE", this.requestDetailFeasibilityService.feasibilityForm.get('ImporteMoraCIRBE').value],
			// ["DescubiertosCuenta", this.requestDetailFeasibilityService.feasibilityForm.get('DescubiertosCuenta').value],
			// ["IngresosRecurrentesTrimestrales", this.requestDetailFeasibilityService.feasibilityForm.get('IngresosRecurrentesTrimestrales').value],
			// ["NumeroRecobrosUltimoAno", this.requestDetailFeasibilityService.feasibilityForm.get('NumeroRecobrosUltimoAno').value],
			// ["ImporteMedioGastosApuestas", this.requestDetailFeasibilityService.feasibilityForm.get('ImporteMedioGastosApuestas').value],
			// ["CantidadMesesGastosApuestas", this.requestDetailFeasibilityService.feasibilityForm.get('CantidadMesesGastosApuestas').value],
			// ["VariacionCaidaIngresos", this.requestDetailFeasibilityService.feasibilityForm.get('VariacionCaidaIngresos').value],
			// ["NumeroPagosPorFinancierasMensualmente", this.requestDetailFeasibilityService.feasibilityForm.get('NumeroPagosPorFinancierasMensualmente').value],
			// ["SaldoMedioMinimoMensual6Meses", this.requestDetailFeasibilityService.feasibilityForm.get('SaldoMedioMinimoMensual6Meses').value],
			// ["LimiteCreditoAsignar", this.requestDetailFeasibilityService.feasibilityForm.get('LimiteCreditoAsignar').value],
		]);
	}

	private evaluateRules(rules: FeasibilityRule[], requestMap: Map<string, any>): boolean {
		this.requestDetailFeasibilityService.ruleFeasibilityResults = [];
		let isFeasibleGlobal = true;
		for (const rule of rules) {
			if (!rule.active) {
				continue;
			}
			const matchingRequest = requestMap.get(rule.fieldName);
			const matchingRequest2 = rule.fieldName2 != null ? requestMap.get(rule.fieldName2) : null;
			const isFeasible = this.requestDetailFeasibilityService.validateRule(rule, { value: matchingRequest, value2: matchingRequest2 });

			this.requestDetailFeasibilityService.ruleFeasibilityResults.push({
				ruleConcept: rule.ruleConcept,
				ruleDescription: rule.ruleDescription,
				fieldName: rule.fieldName,
				fieldName2: rule.fieldName2,
				operator: rule.operator,
				value: rule.value,
				active: rule.active,
				isFeasible: isFeasible,
				translationLabel: rule.translationLabel
			});
			if (!isFeasible) {
				isFeasibleGlobal = false;
			}
		}
		return isFeasibleGlobal;
	}

	// #region Translation
	translateRuleConcept(translationLabel: string): Observable<string> {
		return this.translateService.get(`RULES.${translationLabel}.CONCEPT`);
	}

	translateRuleDescription(translationLabel: string): Observable<string> {
		return this.translateService.get(`RULES.${translationLabel}.DESCRIPTION`);
	}
	// #endregion
}
