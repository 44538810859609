import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { JsonViewerComponent } from '../main/json-viewer/json-viewer.component';
import { InsideDisableComponent } from './inside-disable/inside-disable.component';
import { LoadingComponent } from './loading/loading.component';
import { UsualModule } from '../usual.module';
import { LayoutComponent } from '../main/layout/layout.component';

@NgModule({
	declarations: [
		LoadingComponent,
		InsideDisableComponent,
		JsonViewerComponent,
		LayoutComponent
	],
	imports: [
		CommonModule,
		NgxJsonViewerModule,
		UsualModule
	],
	exports: [
		LoadingComponent,
		InsideDisableComponent,
		JsonViewerComponent,
		LayoutComponent
	]
})
export class UtilsModule { }