import { Component, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from 'src/app/login/login.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	standalone: false,
})
export class HomeComponent implements OnInit {

	constructor(private userIdle: UserIdleService, public loginService: LoginService) { }

	ngOnInit() {
		//Start watching for user inactivity.
		this.userIdle.startWatching();

		// Start watching when user idle is starting.
		this.userIdle.onTimerStart().subscribe();

		// Start watch when time is up.
		this.userIdle.onTimeout().subscribe(() => {
			this.loginService.logout();
			this.loginService.showLoginDialog = true;
			this.stopWatching();
		});
	}

	stop() {
		this.userIdle.stopTimer();
	}

	stopWatching() {
		this.userIdle.stopWatching();
	}

	startWatching() {
		this.userIdle.startWatching();
	}

	restart() {
		this.userIdle.resetTimer();
	}
}
