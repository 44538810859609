export enum OperatorEnum {
    Equals = 'eq',
    NotEquals = 'notEq',
    LessThan = 'lt',
    LessThanOrEqual = 'lte',
    GreaterThan = 'gt',
    GreaterThanOrEqual = 'gte',
    Includes = 'includes',
    DoesNotInclude = 'notIncludes',
    StartsWith = 'startsWith',
    DoesNotStartWith = 'notStartsWith',
    EndsWith = 'endsWith',
    DoesNotEndWith = 'notEndsWith',
    Field1PercentageGteField2 = 'field1PercentageGteField2',
    Field1SumField2GtParameter = 'field1SumField2GtParameter',
    Field1MinusField2GtParameter = 'field1MinusField2GtParameter',
    Field1GteField2Percentage = 'field1GteField2Percentage',
}

export const RowsPerPageDefault = 10;
export const RowsPerPageOptions = [10, 15, 25, 50, 100];

export const FeasibilityJsonFileName = 'feasibility.json';