import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/shared-services/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard  {

	constructor(
    private router: Router,
    private authenticationService: AuthenticationService
	) { }

	canActivate(route: ActivatedRouteSnapshot,  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const currentUser = this.authenticationService.currentUserValue;
		if (currentUser) {
			// logged in so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
		return false;
	}

}
