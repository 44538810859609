<h2>{{ 'ADMINISTRATION.FEASIBILITY_RULE' | translate }}</h2>

<form [formGroup]="feasibilityRuleFormGroup" (ngSubmit)="handleSaveFeasibilityRule()">
    <div>
        <label for="ruleConcept">{{ 'ADMINISTRATION.FEASIBILITY.CONCEPT' | translate }}</label>
        <input id="ruleConcept" pInputText formControlName="ruleConcept">
    </div>
    <div>
        <label for="ruleDescription">{{ 'ADMINISTRATION.FEASIBILITY.DESCRIPTION' | translate }}</label>
        <textarea id="ruleDescription" pInputTextarea formControlName="ruleDescription"></textarea>
    </div>
    <div>
        <label for="fieldName">{{ 'ADMINISTRATION.FEASIBILITY.FIRST_FIELD' | translate }}</label>
        <input id="fieldName" pInputText formControlName="fieldName">
    </div>
    <div>
        <label for="fieldName2">{{ 'ADMINISTRATION.FEASIBILITY.SECOND_FIELD' | translate }}</label>
        <input id="fieldName2" pInputText formControlName="fieldName2">
    </div>
    <div>
        <label for="operator">{{ 'ADMINISTRATION.FEASIBILITY.OPERATOR' | translate }}</label>
        <p-dropdown id="operator" formControlName="operator" [options]="operatorOptions" optionLabel="label"
            optionValue="value" />
    </div>
    <div>
        <label for="value">{{ 'ADMINISTRATION.FEASIBILITY.VALUE' | translate }}</label>
        <input id="value" pInputText formControlName="value">
    </div>
    <div>
        <p-checkbox formControlName="active" binary="true"
            label="{{ 'ADMINISTRATION.FEASIBILITY.ACTIVE' | translate }}" />
    </div>
    <div>
        <button type="submit" pButton [disabled]="feasibilityRuleFormGroup.invalid">{{ 'ADMINISTRATION.SAVE' | translate
            }}</button>
    </div>
</form>