<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="detail-body">
    <div class="detail-documents relevant-info">
        <h2>
            <span class="material-symbols-outlined hashtag"> tag </span> {{ 'REQUEST_DETAIL.DOCUMENTS_ANALYZED' |
            translate }}
        </h2>
        <div class="detail-content">
            <div class="detail-content-header">
                <span class="material-symbols-outlined custom-doc ">
                    description
                </span>
                <h3>{{ 'REQUEST_DETAIL.ENRICHMENT' | translate }}</h3>

                <div class="accordion-buttons">
                    <!-- EXPAND ALL BUTTON -->
                    <p-button
                        *ngIf="requestDetailDocumentsService.enrichmentAccordion.length > 0 && requestDetailDocumentsService.activedAccordionItems.length !== requestDetailDocumentsService.enrichmentAccordion.length"
                        [disabled]="requestDetailDocumentsService.activedAccordionItems.length === requestDetailDocumentsService.enrichmentAccordion.length"
                        (click)="requestDetailDocumentsService.expandAll()"
                        pTooltip="{{ 'REQUEST_DETAIL.EXPAND_ALL' | translate }}" tooltipPosition="top"
                        styleClass="p-button-rounded p-button-secondary p-button-text">
                        <span class="material-symbols-outlined"> keyboard_arrow_down </span>
                    </p-button>

                    <!-- COLLAPSE ALL BUTTON -->
                    <p-button
                        *ngIf="requestDetailDocumentsService.enrichmentAccordion.length > 0 && requestDetailDocumentsService.activedAccordionItems.length !== 0"
                        [disabled]="requestDetailDocumentsService.activedAccordionItems.length === 0"
                        (click)="requestDetailDocumentsService.colapseAll()"
                        pTooltip="{{ 'REQUEST_DETAIL.COLLAPSE_ALL' | translate }}" tooltipPosition="top"
                        styleClass="p-button-rounded p-button-secondary p-button-text">
                        <span class="material-symbols-outlined"> keyboard_arrow_up </span>
                    </p-button>
                </div>
            </div>
            <div class="">
                <!-- <p class="detail-item-name">{{enrichment.title}}</p> -->
                <p-accordion [multiple]="true"
                    [(activeIndex)]="this.requestDetailDocumentsService.activedAccordionItems">
                    <p-accordionTab
                        *ngFor="let enrichment of this.requestDetailDocumentsService.enrichmentAccordion; let i = index"
                        [header]="enrichment.title | translate"
                        (onOpen)="this.requestDetailDocumentsService.onTabOpen(i)"
                        (onClose)="this.requestDetailDocumentsService.onTabClose(i)">
                        <table>
                            <thead>
                                <th>{{ 'REQUEST_DETAIL.KEY' | translate }}</th>
                                <th>{{ 'REQUEST_DETAIL.VALUE' | translate }}</th>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let e of enrichment.elements">
                                    @if(feasibilityKeys.includes(e.key)){
                                    <tr>
                                        <td>{{ translateFeasibilityKey(e.key) }}</td>
                                        @if(isNumber(e.value)){
                                        <td class="W200">{{ e.value | number:'1.0-2':'es-ES' }}</td>
                                        }@else{
                                        <td class="W200">{{ translateIfBoolean(e.value) }}</td>
                                        }
                                    </tr>
                                    }@else{
                                    <tr>
                                        <td>{{ e.key }}</td>
                                        @if(isNumber(e.value)){
                                        <td class="W200">{{ e.value | number:'1.0-2':'es-ES' }}</td>
                                        }@else {
                                        <td class="W200">{{ e.value }}</td>
                                        }
                                    </tr>
                                    }
                                </ng-container>
                            </tbody>
                        </table>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
    <div class="detail-documents">
        <h2>
            <span class="material-symbols-outlined hashtag"> tag </span> {{ 'REQUEST_DETAIL.REQUEST_RECEIVED_DOCUMENTS'
            | translate }}
        </h2>
        <div class="detail-content">
            <div class="detail-content-header">
                <span class="material-symbols-outlined custom-doc ">
                    description
                </span>
                <h3>{{ 'REQUEST_DETAIL.TAX_DOCUMENTATION' | translate }}</h3>
            </div>
            <div class="detail-content-item" *ngFor="let name of this.documentNames">
                <p class="detail-item-name">{{name}}</p>
                <div class="detail-item-links">
                    <p-button (click)="this.openPDF(name)" pTooltip="{{ 'REQUEST_DETAIL.OPEN_PDF' | translate }}"
                        tooltipPosition="top">
                        <span class="material-symbols-outlined"> visibility </span>
                        {{ 'REQUEST_DETAIL.PDF' | translate }}
                    </p-button>
                    <p-button (click)="this.downloadPDF(name)"
                        pTooltip="{{ 'REQUEST_DETAIL.DOWNLOAD_PDF' | translate }}" tooltipPosition="top">
                        <span class="material-symbols-outlined"> download </span>
                        {{ 'REQUEST_DETAIL.PDF' | translate }}
                    </p-button>
                    <p-button (click)="this.openJSON(name)" pTooltip="{{ 'REQUEST_DETAIL.OPEN_JSON' | translate }}"
                        tooltipPosition="top">
                        <span class="material-symbols-outlined"> visibility </span>
                        {{ 'REQUEST_DETAIL.JSON' | translate }}
                    </p-button>
                    <p-button (click)="this.downloadJSON(name)"
                        pTooltip="{{ 'REQUEST_DETAIL.DOWNLOAD_JSON' | translate }}" tooltipPosition="top">
                        <span class="material-symbols-outlined"> download </span>
                        {{ 'REQUEST_DETAIL.JSON' | translate }}
                    </p-button>
                </div>
            </div>
        </div>
    </div>
    <div class="detail-history">
        <div (click)="requestDetailService.isHistorySidebarVisible = true">
            <span class="material-symbols-outlined"> history </span>
            <p>{{ "REQUEST_DETAIL.HISTORY" | translate }}</p>
        </div>
        @if(!requestDetailService.feasibilityData.isCreditOperationMade){
        <div (click)="requestDetailService.isCalculateViabilitySidebarVisible = true">
            <span class="material-symbols-outlined"> dataset </span>
            <p>{{ "REQUEST_DETAIL.CALCULATE_FEASIBILITY" | translate }}</p>
        </div>
        }
    </div>
</div>