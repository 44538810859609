import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './main/layout/layout.component';
import { MyRequestsComponent } from './main/my-requests/my-requests.component';
import { RequestDetailComponent } from './main/request-detail/request-detail.component';
import { HomeComponent } from './main/home/home.component';
import { JsonViewerComponent } from './main/json-viewer/json-viewer.component';
import { AdministrationComponent } from './main/administration/administration.component';

const routes: Routes = [
	{ path: 'check-layout', component: LayoutComponent, canActivate: [AuthGuard] },
	{ path: 'json-viewer', component: JsonViewerComponent, canActivate: [AuthGuard] },
	{ path: 'login', component: LoginComponent },
	{
		path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
			{ path: 'my-requests/request-detail/:id', component: RequestDetailComponent, canActivate: [AuthGuard] },
			{ path: 'my-requests', component: MyRequestsComponent, canActivate: [AuthGuard] },
			{ path: 'administration', component: AdministrationComponent, canActivate: [AuthGuard] }
		]
	},

	// otherwise redirect to home
	{ path: '**', redirectTo: 'home/my-requests' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }