import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { LoginService } from '../login/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(private loginService : LoginService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(catchError(err => {
			if (err.status === 401) {
				// auto logout if 401 response returned from api
				this.loginService.logout();
				location.reload();
			}

			//const error = err.error.message || err.statusText;
			return throwError(err);
		}));
	}
}
