import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsualModule } from '../usual.module';
import { LoginComponent } from './login.component';
import { UtilsModule } from '../utils/utils.module';



@NgModule({
	declarations: [
		LoginComponent
	],
	imports: [
		CommonModule,
		UsualModule,
		UtilsModule
	],
	exports: [
		LoginComponent
	]
})
export class LoginModule { }
