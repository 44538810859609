import { Injectable } from '@angular/core';
import { BelenderWidgetResponse } from 'src/app/models/BelenderWidgetResponse';
import { DeleteRequest } from 'src/app/models/DeleteRequest';
import { DeleteResponse } from 'src/app/models/DeleteResponse';
import { ODDetailResponse } from 'src/app/models/ODDetailResponse';
import { ApiService } from 'src/shared-services/api.service';
import { saveAs } from 'file-saver';
import { FeasibilityData } from 'src/app/models/FeasibilityData';
import { ReadJsonRequest } from 'src/app/models/ReadJsonRequest';
import { FeasibilityJsonFileName } from 'src/shared/constants/common.constants';
import { ReadJsonResponse } from 'src/app/models/ReadJsonResponse';
import { Router } from '@angular/router';
import { ToastService } from 'src/shared-services/toast.service';
import { RequestDetailDocumentsService } from './request-detail-documents/request-detail-documents.service';

@Injectable({
	providedIn: 'root'
})
export class RequestDetailService {

	public isLoading = false;
	public request: ODDetailResponse;
	public queryId: string;
	public lastQueryHour: Date;
	public BORRAR_BelenderWidget: any;
	public isHistorySidebarVisible = false;
	public isCalculateViabilitySidebarVisible = false;
	public feasibilityData: FeasibilityData = {
		isCreditOperationMade: false,
		isCreditGranted: false,
		creditAmount: undefined
	};
	public feasibilityEnrichmentData: Record<string, any> | undefined = undefined;

	constructor(
		private apiService: ApiService,
		private router: Router,
		private toastService: ToastService,
		private requestDetailDocumentsService: RequestDetailDocumentsService) { }

	async getBelenderWidget(param1: string, param2: string, param3: string): Promise<BelenderWidgetResponse> {
		return this.apiService.get(`requests/belender-widget?param1=${param1}&param2=${param2}&param3=${param3}`);
	}

	getRequestFullPersonName(): string {
		if (this.request != null) {
			return this.request.personQuery?.firstName + ' ' +
				this.request.personQuery?.firstSurname + ' ' +
				(this.request.personQuery.secondSurname ? this.request.personQuery.secondSurname : '');
		}
		return null;
	}

	async saveDocument(url: string, fileName: string) {
		const file = await this.apiService.postBlob(`files/amazon-file`, { url: url, fileName: fileName });
		saveAs(file, fileName);
	}

	saveJSON(json: object, fileName: string) {
		// Convert the object to a JSON string
		const jsonString = JSON.stringify(json);
		// Create a Blob from the JSON string
		const blob = new Blob([jsonString], { type: 'application/json' });
		saveAs(blob, fileName);
	}

	async getRequestFromUrl() {
		this.isLoading = true;
		this.queryId = this.validateURLAndGetRequestId(this.router.url);

		if (this.queryId) {
			try {
				await this.setRequestDetailServiceRequest();	// wait till the data is fetched
				await this.setFeasibilityData();	// add the feasibility data to the accordion
			}
			catch {
				this.toastService.showError('TOAST.ERROR', 'REQUEST_DETAIL.ERROR_NOT_FOUND', true);
			} finally {
				this.isLoading = false;
			};
		} else {
			this.toastService.showError('TOAST.ERROR', 'REQUEST_DETAIL.NO_QUERY_ID', true);
			this.isLoading = false;
		};
	}

	async refreshRequest() {
		this.isLoading = true;
		try {
			await this.setRequestDetailServiceRequest();	// wait till the data is fetched
			await this.setFeasibilityData();	// add the feasibility data to the accordion
		} catch {
			this.toastService.showError('TOAST.ERROR', 'REQUEST_DETAIL.ERROR_NOT_FOUND', true);
		} finally {
			this.isLoading = false;
		}
	}

	async cancelRequest(deleteRequest: DeleteRequest): Promise<DeleteResponse> {
		return this.apiService.post<DeleteResponse>('requests/delete-request', deleteRequest);
	}

	private validateURLAndGetRequestId(url: string): string | null {
		const regex = /request-detail\/([0-9]{14}-[A-Z0-9]{18})/;
		const matches = url.match(regex);
		if (matches && matches[1]) {
			return matches[1];
		} else {
			return null;
		}
	}

	private async setRequestDetailServiceRequest() {
		this.request = await this.apiService.get<ODDetailResponse>(`requests/request-detail?queryId=${this.queryId}`);
		this.lastQueryHour = new Date();
	}

	private async setFeasibilityData() {
		const request: ReadJsonRequest = {
			queryId: this.queryId,
			filename: FeasibilityJsonFileName
		};
		try {
			const response = await this.apiService.post<ReadJsonResponse>('requests/read-json', request);
			if (!this.feasibilityEnrichmentData) {
				this.feasibilityEnrichmentData = response.data;
				if (this.feasibilityEnrichmentData) {
					this.requestDetailDocumentsService.addFeasibilityEnrichmentDataToAccordion(this.feasibilityEnrichmentData);
				}
				this.requestDetailDocumentsService.expandAll();
			}
			if (response.data['Viable'] === true) {
				this.feasibilityData = {
					isCreditOperationMade: true,
					isCreditGranted: true,
					creditAmount: Number(response.data['CalculoDelLimite'])
				};
			} else {
				this.feasibilityData = {
					isCreditOperationMade: true,
					isCreditGranted: false,
					creditAmount: Number(response.data['CalculoDelLimite'])
				};
			}
		} catch {
			this.feasibilityData = {
				isCreditOperationMade: false,
				isCreditGranted: false,
				creditAmount: undefined
			};
		}
	}

	resetFeasibilityData() {
		this.feasibilityEnrichmentData = undefined;
		this.feasibilityData = {
			isCreditOperationMade: false,
			isCreditGranted: false,
			creditAmount: undefined
		};
	}
}