import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewRequestComponent } from './new-request.component';
import { UsualModule } from 'src/app/usual.module';
import { UtilsModule } from 'src/app/utils/utils.module';

@NgModule({
	declarations: [
		NewRequestComponent
	],
	imports: [
		CommonModule,
		UsualModule,
		UtilsModule
	],
	exports: [
		NewRequestComponent
	]
})
export class NewRequestModule { }
