import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterResponse } from 'src/app/models/FilterResponse';
import { StatusConfig } from 'src/app/models/StatusConfig';
import { ApiService } from 'src/shared-services/api.service';

@Injectable({
	providedIn: 'root'
})
export class RequestService {

	constructor(
		private api: ApiService,
		private translateService: TranslateService
	) {
		this.api.loadStatusConfig().subscribe((config) => this.statusConfig = config);
	}

	statusConfig: StatusConfig;

	personTypes = [
		{ title: 'FILTER.PERSON_TYPES.FREELANCER', id: '01' },
		{ title: 'FILTER.PERSON_TYPES.EMPLOYEE', id: '00' }];

	queryTypes = [
		{ title: 'FILTER.QUERY_TYPES.PHYSICAL_PERSON', id: 'PhysicalPerson' }];

	getPersonTypeTitle(personType: string) {
		const _personTypeId = this.personTypes.find((person) => person.id == personType);
		return _personTypeId?.title;
	}

	getQueryTypeTitle(queryType: string) {
		const _queryTypeId = this.queryTypes.find((query) => query.id == queryType);
		return _queryTypeId?.title;
	}

	// TODO: ARREGLAR ÑAPA ESTADOS 1.0 
	// Cambiar esta pedazo de ñapa por favor
	// Ahora para coger el estado, como nos gusta construir castillos en el aire,
	// se debe pasar el substatus, y asi, se saca el estado.
	getRequestStatusTitleFromSubStatus(subStatusId: string): string {
		const substatus = this.statusConfig?.substatus.find((substat) => substat.id == subStatusId);
		return substatus != null ? substatus.status : subStatusId;
	}

	// TODO: ARREGLAR ÑAPA ESTADOS 2.0 
	// Cambiar esta pedazo de ñapa por favor
	// Ahora para coger el estado, como nos gusta construir castillos en el aire,
	// se debe pasar el substatus, y asi, se saca el estado.
	getRequestStatusIdFromSubStatus(subStatusId: string): string {
		const substatus = this.statusConfig?.substatus.find((substat) => substat.id == subStatusId);
		return substatus != null ? substatus.status_id : subStatusId;
	}

	getRequestStatusTitle(status: string) {
		const _statusId = this.statusConfig?.status.find((stat) => stat.id == status);
		return _statusId?.title;
	}

	getRequestSubstatusTitle(substatus: string) {
		const _substatusId = this.statusConfig?.substatus.find((sub_stat) => sub_stat.id == substatus);
		return _substatusId?.title ?? substatus;
	}

	propertiesInitializer(constObject: { title: string, id: string }[]) {
		const translatedProperty$ = [];
		for (const property of constObject) {
			translatedProperty$.push(this.translateService.get(property.title)
				.subscribe((res: string) => property.title = res));
		}
	}

	// La clase FilterResponse contiene la lista y alguna propiedad mas como el numero de peticiones.
	public async getAllRequests(): Promise<FilterResponse> {
		return await this.api.post<FilterResponse>(`requests/filter-requests`, {});
	}

	public getStatusIcon(status: string): string {
		switch (status) {
		case "Created":
			return "bookmark_added";
		case "Processing":
			return "exit_to_app";
		case "Downloading":
			return "downloading";
		case "Analyzing":
			return "troubleshoot";
		case "Success":
			return "task";
		case "Canceled":
			return "do_not_disturb_on";
		case "Error":
			return "error";
		default: return "icon_not_found";
		}
	}
}
