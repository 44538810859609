import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsualModule } from 'src/app/usual.module';
import { HeaderComponent } from './header.component';
import { UtilsModule } from 'src/app/utils/utils.module';

@NgModule({
	declarations: [
		HeaderComponent
	],
	imports: [
		CommonModule,
		UsualModule,
		UtilsModule,
	], exports: [
		HeaderComponent
	]
})
export class HeaderModule { }
