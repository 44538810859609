<div class="loading-container" *ngIf="isLoading">
    <p-progressSpinner ariaLabel="loading" styleClass="custom-spinner" />
    <div class="message-container">
        <div class="message" *ngFor="let msg of messages; let i = index" [class.current]="i===currentMessageIndex">
            {{msg}}</div>
    </div>
</div>

<app-inside-disable [isDisabled]="isDisabled">
    <ng-content></ng-content>
</app-inside-disable>