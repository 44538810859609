import { Injectable } from '@angular/core';
import { EnrichmentAccordion, EnrichmentAccordionElement } from 'src/app/models/EnrichmentCollapse';
import { ODDetailResponse } from 'src/app/models/ODDetailResponse';

@Injectable({
	providedIn: 'root'
})
export class RequestDetailDocumentsService {

	public enrichmentAccordion: EnrichmentAccordion[] = [];
	public activedAccordionItems: number[] = [];

	addEnrichmentDataToAccordion(enrichment: ODDetailResponse) {
		this.enrichmentAccordion = [];

		// TODO: delete temporal OD_FEASIBILITY_FIELDS when OD sends the correct data
		const elements: EnrichmentAccordionElement[] = [];
		elements.push({ key: 'Facturacion', value: 8500 });
		elements.push({ key: 'OD_NUMANOEMPACTUAL', value: 4 });
		elements.push({ key: 'OD_Ingresos', value: 15876 });
		elements.push({ key: 'D4A', value: 'A' });
		this.enrichmentAccordion.push({ title: 'OD_campos_viabilidad', elements: elements });

		// dictionary keys of Enrichment
		for (const key in enrichment.enrichment) {
			const elements: EnrichmentAccordionElement[] = [];

			// every key in the dictionary
			for (const secondKey in enrichment.enrichment[key]) {
				elements.push({ key: secondKey, value: enrichment.enrichment[key][secondKey] });
			}
			this.enrichmentAccordion.push({ title: key, elements: elements });
		}
	}

	addFeasibilityEnrichmentDataToAccordion(feasibilityInfo: Record<string, any>) {
		const elements: EnrichmentAccordionElement[] = [];
		for (const key in feasibilityInfo) {
			elements.push({ key: key, value: feasibilityInfo[key] });
		}
		this.enrichmentAccordion.push({ title: 'REQUEST_DETAIL.MANUAL_INFO', elements: elements });
	}

	onTabOpen(index: number) {
		if (!this.activedAccordionItems.includes(index)) {
			this.activedAccordionItems.push(index);
		}
	}

	onTabClose(index: number) {
		this.activedAccordionItems = this.activedAccordionItems.filter(i => i !== index);
	}

	expandAll() {
		this.activedAccordionItems = this.enrichmentAccordion.map((_, index) => index);
	}

	colapseAll() {
		this.activedAccordionItems = [];
	}
}
