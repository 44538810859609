<div *ngIf="this.requestDetailService.request">
    <div class="detail-header">
        <div>
            <div class="flex">
                <button pButton (click)="this.navigateToMyRequests()" [rounded]="true" [text]="true" class="P8"
                    styleClass="p-button-rounded p-button-danger p-button-text">
                    <span class="material-symbols-outlined">arrow_back</span>
                </button>
                <h3 pTooltip="{{'REQUEST_DETAIL.NAME' | translate }}" tooltipPosition="top">
                    {{this.requestDetailService.getRequestFullPersonName()}}
                </h3>
                <span *ngIf="this.requestDetailService.request.personQuery.birthDate" class="bold400 font14"
                    pTooltip="{{ 'REQUEST_DETAIL.BIRTH_DATE' | translate }}"
                    tooltipPosition="top">({{this.requestDetailService.request.personQuery.birthDate | date:
                    'dd/MM/yyyy'}})</span>
                <span class="mini-separator"></span>
                <span class="bold600 font14" pTooltip="{{ 'REQUEST_DETAIL.LABORAL_REGIME' | translate }}"
                    tooltipPosition="top">{{this.requestService.getPersonTypeTitle(this.requestDetailService.request.personQuery.personType)
                    | translate}}</span>
                <p class="font11" pTooltip="{{ 'REQUEST_DETAIL.QUERY_ID' | translate }}" tooltipPosition="top">
                    {{this.requestDetailService.request.personQuery.queryId}}</p>
            </div>
            <div class="flex PL50">
                <p pTooltip="{{ 'REQUEST_DETAIL.QUERY_TYPE' | translate }}" tooltipPosition="top">
                    <span class="material-symbols-outlined"> contract </span>
                    {{this.requestService.getQueryTypeTitle(this.requestDetailService.request.personQuery.queryType) |
                    translate}}
                </p>
                <p pTooltip="{{ 'REQUEST_DETAIL.EMAIL' | translate }}" tooltipPosition="top"><span
                        class="material-symbols-outlined">
                        mail
                    </span>{{this.requestDetailService.request.personQuery.email}}</p>
                <p pTooltip="{{ 'REQUEST_DETAIL.PHONE' | translate }}" tooltipPosition="top"><span
                        class="material-symbols-outlined">
                        phone
                    </span>{{this.requestDetailService.request.personQuery.phoneNumber}}</p>
                <p pTooltip="{{ 'REQUEST_DETAIL.ID_NUMBER' | translate }}" tooltipPosition="top"><span
                        class="material-symbols-outlined">
                        id_card
                    </span>{{this.requestDetailService.request.personQuery.personId}}</p>
            </div>
        </div>
        <div class="flex">
            <div class="flex-column" *ngIf="this.requestDetailService.request.personQuery.personIdExpirationDate">
                <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.ID_EXPIRATION_DATE' | translate }}</p>
                <p class="date-box">{{this.requestDetailService.request.personQuery.personIdExpirationDate | date:
                    'dd/MM/yyyy'}}</p>
            </div>
            <div class="flex-column">
                <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.REQUEST_DATE' | translate }}</p>
                <p class="date-box">{{this.requestDetailService.request.personQuery.createdDateTime | date:
                    'dd/MM/yyyy HH:mm'}}</p>
            </div>
            <div class="flex-column" *ngIf="this.requestDetailService.request.personQuery.isDocumentsDowloadCompleted">
                <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.DOWNLOAD_DOCUMENTS' | translate }}</p>
                <p class="date-box document-check">
                    <span class="material-symbols-outlined">
                        done_all
                    </span>
                </p>
            </div>
            <div class="flex-column" *ngIf="requestDetailService.feasibilityData.isCreditOperationMade">
                @if(requestDetailService.feasibilityData.isCreditGranted){
                <p class=" font11 MB0 center">{{ 'REQUEST_DETAIL.FEASIBLE' | translate }}</p>
                <p class="date-box document-check">
                    <span class="material-symbols-outlined">
                        check
                    </span>
                </p>
                }@else {
                <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.NOT_FEASIBLE' | translate }}</p>
                <p class="date-box document-denied">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </p>
                }
            </div>
        </div>
        <div class="flex-column align-center">
            <!-- TODO: ARREGLAR ÑAPA ESTADO 5.0 -->
            <p class="actual-status {{this.requestService.getRequestStatusIdFromSubStatus(this.requestDetailService.request.personQuery.substatus)}}"
                pTooltip="{{ 'REQUEST_DETAIL.ACTUAL_STATUS' | translate }}" tooltipPosition="top">{{
                this.requestService.getRequestStatusTitleFromSubStatus(this.requestDetailService.request.personQuery.substatus)
                | translate }}</p>
            <!-- <p class="actual-sub-status" pTooltip="{{ 'REQUEST_DETAIL.SUBSTATUS' | translate }}" tooltipPosition="top" >{{ this.requestService.getRequestSubstatusTitle(this.requestDetailService.request.personQuery.substatus) | translate }}</p> -->
        </div>
    </div>
</div>