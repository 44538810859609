import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input() isLoading: boolean;
  @Input() isDisabled = false;
  @Input() messages: string[] = [];
  currentMessageIndex = 0;

  constructor() {
  	this.cycleMessages();
  }

  cycleMessages() {
  	setInterval(() => {
  		this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length;
  	}, 5000); // Cambia cada 3 segundos
  }
}