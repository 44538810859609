import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {

	constructor(private translateService: TranslateService) { }

	range(size: number, startAt = 0, step = 1): number[] {
		return [...Array(size).keys()].map(i => startAt + i * step);
	}

	translateBoolean(value: boolean): string {
		return value ? this.translateService.instant('BOOLEAN.TRUE') : this.translateService.instant('BOOLEAN.FALSE');
	}
}