import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterResponse } from 'src/app/models/FilterResponse';
import { Router } from '@angular/router';
import { RequestsFilterService } from './requests-filter.service';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { RequestService } from './request.service';
import { RequestDetailService } from '../request-detail/request-detail.service';
import { NewRequestService } from '../new-request/new-request.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { Table } from 'primeng/table';

@Component({
	selector: 'app-my-requests',
	standalone: false,
	templateUrl: './my-requests.component.html'
})
export class MyRequestsComponent implements OnInit, OnDestroy {

	public filterResponse: FilterResponse;
	public sidebarVisible = false;
	private filterHandlerSubscription!: Subscription;
	@ViewChild('table') table: Table;

	constructor(private router: Router,
		public requestsFilterService: RequestsFilterService,
		public requestService: RequestService,
		public ñapaServicioBelender: RequestDetailService,
		public newRequestService: NewRequestService,
		public utilsService: UtilsService,
	) {
		this.filterHandlerSubscription = this.requestsFilterService.filterHandler().subscribe((filteredResponse: FilterResponse) => {
			this.filterResponse = filteredResponse;
		});
	}

	async ngOnInit() {
		await this.requestsFilterService.search();
	}

	ngOnDestroy(): void {
		if (this.filterHandlerSubscription) {
			this.filterHandlerSubscription.unsubscribe();
		}
	}

	goToDetail(queryId: string) {
		this.router.navigate([`/home/my-requests/request-detail/${queryId}`]);
		this.ñapaServicioBelender.BORRAR_BelenderWidget = this.filterResponse.requestList.find(x => x.queryId === queryId).consentUrl;
	}

	exportToExcel(): void {
		if (this.filterResponse && this.filterResponse.requestList.length > 0) {
			const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filterResponse.requestList);
			const workbook: XLSX.WorkBook = {
				Sheets: { 'requests': worksheet },
				SheetNames: ['requests']
			};

			const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

			this.saveAsExcelFile(excelBuffer, 'opendata_requests');
		}
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
		saveAs(data, `${fileName}_export_${new Date().getTime()}.xlsx`);
	}

	newRequest() {
		this.newRequestService.myForm.reset();
		this.sidebarVisible = true;
	}

	globalSearch($event: Event) {
		const input = $event.target as HTMLInputElement;
		this.requestsFilterService.globalSearchSubject.next({ inputValue: input.value, inputFallBackReference: input });
	}

	checkFilterFieldHasValue(field: string): boolean {
		return this.requestsFilterService.form.get(field).value != null && this.requestsFilterService.form.get(field).value != '';
	}

	showIfTableIsLoadingAndEmpty(): boolean {
		return (this.filterResponse?.requestList?.length == 0 || this.filterResponse == null) && this.requestsFilterService.isLoading;
	}

	onSortChange(event: any) {
		const field = event.field;
		const order = event.order;
		this.requestsFilterService.setSortField(field, order);
	}

	async sync() {
		this.requestsFilterService.resetSortField();
		if (this.table) {
			this.table.reset();	// refresh the table to show the new data
		}
		await this.requestsFilterService.search(true);
	}

}
