import { Component } from '@angular/core';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/shared-services/api.service';
import { AuthenticationService } from 'src/shared-services/authentication.service';
import { ErrorService } from 'src/shared-services/error.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.css']
})
export class LayoutComponent {
	loading = false;
	users: User[] = [];

	constructor(private authenticationService: AuthenticationService, private errorService: ErrorService, public api: ApiService) { }

}