<div class="active-filters">
    <ng-container *ngFor="let key of formKeys">
        <ng-container [ngSwitch]="key">
            <ng-container *ngIf="this.queryId">
                <div class="applied-filter" *ngSwitchCase="'queryId'">
                    <span class="filter-name">{{ "FILTER.QUERY_ID" | translate }}</span>
                    <span class="filter-value">{{this.queryId}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'queryType'">
                    <span class="filter-name">{{ "FILTER.REQUEST_TYPE" | translate }}</span>
                    <span class="filter-value">{{requestService.getQueryTypeTitle(this.getFormControlObject(key).value)
                        | translate }}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.personId">
                <div class="applied-filter" *ngSwitchCase="'personId'">
                    <span class="filter-name">{{ "FILTER.NAME_ID" | translate }}</span>
                    <span class="filter-value">{{this.personId}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.firstName">
                <div class="applied-filter" *ngSwitchCase="'firstName'">
                    <span class="filter-name">{{ "FILTER.PERSON_ENTITY" | translate }}</span>
                    <span class="filter-value">{{this.firstName}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.email">
                <div class="applied-filter" *ngSwitchCase="'email'">
                    <span class="filter-name">{{ "FILTER.EMAIL" | translate }}</span>
                    <span class="filter-value">{{this.email}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.phoneNumber">
                <div class="applied-filter" *ngSwitchCase="'phoneNumber'">
                    <span class="filter-name">{{ "FILTER.PHONE" | translate }}</span>
                    <span class="filter-value">{{this.phoneNumber}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'personType'">
                    <span class="filter-name">{{ "MY_REQUESTS.LABORAL_REGIME" | translate }}</span>
                    <span class="filter-value">{{requestService.getPersonTypeTitle(this.getFormControlObject(key).value)
                        | translate}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'startDate'">
                    <span class="filter-name">{{'FILTER.FROM' | translate }}</span>
                    <span class="filter-value">{{this.getFormControlObject(key).value | date: 'dd/MM/yyy'}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'endDate'">
                    <span class="filter-name">{{'FILTER.TO' | translate }}</span>
                    <span class="filter-value">{{this.getFormControlObject(key).value | date: 'dd/MM/yyy'}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>

            <ng-container *ngIf="this.getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'status'">
                    <span class="filter-name">{{ "MY_REQUESTS.STATUS" | translate }}</span>
                    <span
                        class="filter-value">{{requestService.getRequestStatusTitle(this.getFormControlObject(key).value)
                        | translate}}</span>
                    <button class="clear-filter" (click)="this.clearFilter(key)"><span class="material-symbols-outlined"> cancel
                        </span></button>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="this.requestsFilterService.query !== '' && this.requestsFilterService.query !== null">
        <div class="applied-filter">
            <span class="filter-name">{{ "FILTER.STRING_QUERY" | translate }}</span>
            <span class="filter-value">{{this.requestsFilterService.query}}</span>
            <button class="clear-filter" (click)="this.requestsFilterService.clearGlobalSearch()"><span class="material-symbols-outlined"> cancel
                </span></button>
        </div>
    </ng-container>
    <ng-container *ngIf="thereIsMoreThanOneFilterActive()">
        <button class="btn-clean-filters" (click)="this.clearAllFiltersAndGlobalSearch()"><span class="material-symbols-outlined"> filter_alt_off
            </span>{{ "FILTER.CLEAN_ALL" | translate }}</button>
    </ng-container>
</div>