import { Directive, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';

@Directive({
	// global directive
	selector: 'p-table'
})
export class TableDirective {
  @Output() rowsPerPageChanged = new EventEmitter<number>();

  constructor(private table: Table, private translateService: TranslateService) {
  	this.table.paginator = true;
  	this.table.showCurrentPageReport = true;
  	//this.table.currentPageReportTemplate = "Mostrando del {first} al {last} de {totalRecords} resultados";
  	this.table.currentPageReportTemplate = "TABLE.PAGE_REPORT";
  	this.table.rows = 25;
  	this.table.rowsPerPageOptions = [5, 10, 25, 100, 500];
  	this.table.sortMode = 'single';
  	this.table.scrollable = true;
  	// this.table.styleClass = 'p-datatable-gridlines';
  	this.table.showLoader = false;

  	this.currentPagesReportTemplateInitializer('TABLE.PAGE_REPORT');
    
  	this.translateService.onLangChange.subscribe(() => {
  		this.currentPagesReportTemplateInitializer('TABLE.PAGE_REPORT');
  	});
  }

  private currentPagesReportTemplateInitializer(key: string) {
  	return this.translateService.get(key).subscribe((res: string) => this.table.currentPageReportTemplate = res);
  }
}
