<p-sidebar *ngIf="this.requestDetailService.isHistorySidebarVisible"
  [(visible)]="this.requestDetailService.isHistorySidebarVisible" position="right" styleClass="W700" dismissible="false"
  closeOnEscape="false">
  <ng-template pTemplate="header">
    <div class="sidebar-header">
      <h6 class="sidebar-title">{{ 'REQUEST_DETAIL.HISTORY' | translate }}</h6>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="MT20">
      <table>
        <thead>
          <th class="W160">{{ 'REQUEST_DETAIL.DATE_HOUR' | translate }}</th>
          <th>{{ 'REQUEST_DETAIL.ACTION' | translate }}</th>
        </thead>
        <tbody>
          <ng-container>
            <tr>
              <td>05/10/1983</td>
              <td>Accion predeterminnada</td>
            </tr>
            <tr>
              <td>05/10/1983</td>
              <td>Accion predeterminnada</td>
            </tr>
            <tr>
              <td>05/10/1983</td>
              <td>Accion predeterminnada</td>
            </tr>
            <tr>
              <td>05/10/1983</td>
              <td>Accion predeterminnada</td>
            </tr>
            <tr>
              <td>05/10/1983</td>
              <td>Accion predeterminnada</td>
            </tr>
            <tr>
              <td>05/10/1983</td>
              <td>Accion predeterminnada</td>
            </tr>
            <tr>
              <td>05/10/1983</td>
              <td>Accion predeterminnada</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-template>
</p-sidebar>

<p-sidebar *ngIf="requestDetailService.isCalculateViabilitySidebarVisible"
  [(visible)]="this.requestDetailService.isCalculateViabilitySidebarVisible" position="right" styleClass="W1000"
  dismissible="false" closeOnEscape="false">

  <ng-template pTemplate="header">
    <div class="sidebar-header">
      <h6 class="sidebar-title">{{ 'REQUEST_DETAIL.CALCULATE_FEASIBILITY' | translate }}
        <!--<span class="new-request-specs">Texto de prueba</span>-->
      </h6>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <app-request-detail-feasibility />
  </ng-template>

</p-sidebar>

<app-loading [isLoading]="requestDetailService.isLoading">
  <ng-container *ngIf="requestDetailService.request !== null">
    <p-sidebar [(visible)]="isBelenderSidebarVisible" *ngIf="isBelenderSidebarVisible" position="right"
      styleClass="W700" dismissible="false" closeOnEscape="false">
      <ng-template pTemplate="header">
        <div class="sidebar-header">
          <h6 class="sidebar-title">{{ "MY_REQUESTS.CONTINUE" | translate }}</h6>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <app-new-request [newRequestStatus]="newRequestStatus" [trustedConsentUrlBoxed]="consentBelenderUrl"
          [belenderStatus]="belenderStatus"></app-new-request>
      </ng-template>
    </p-sidebar>

    <app-loading [isLoading]="requestDetailService.isLoading">
      <!-- <ng-container *ngIf="items.length > 0;">
        <p>(BETA) Recordatorio: Si la peticion ha sido recien creada, puede no haber documentos.</p>
        <h4>El estado actual de la peticion es: {{requestDetailService.request?.personQuery?.status}}</h4>
        <p-menubar [model]="items"></p-menubar>
    </ng-container> -->
      <ng-container>
        <app-request-detail-header></app-request-detail-header>
        <div class="detail-body-buttons">
          <div class="request-detail-buttons" *ngIf="this.requestDetailService.request">
            <button *ngIf="!hideCancelButton()" class="outline-btn" (click)="this.confirmCancelRequest($event)">
              <span class="material-symbols-outlined"> block </span>
              {{ "REQUEST_DETAIL.CANCEL" | translate }}</button>

            <button class="outline-btn" (click)="requestDetailService.refreshRequest()">
              <span class=" material-symbols-outlined ico-list"> sync </span>

              <div class="PR10 flex-column" style="display: flex; flex-direction: column; padding-right: 10px;">
                {{ "MY_REQUESTS.SYNC" | translate }}
                <span class="font9">{{ "MY_REQUESTS.LAST_TIME_SYNC" | translate }} {{requestDetailService.lastQueryHour
                  | date:
                  "HH:mm"}}</span>
              </div>
            </button>
            <button *ngIf="verifyRequestCanBeReopened()" class="filled-btn" (click)="reopenBelenderWidget()">
              <span class="material-symbols-outlined">
                autoplay
              </span>
              {{ "REQUEST_DETAIL.CONTINUE_OTP" | translate }}
            </button>
          </div>
        </div>

        <app-request-detail-documents *ngIf="requestDetailService.request"
          [response]="this.requestDetailService.request"></app-request-detail-documents>
        <router-outlet></router-outlet>
      </ng-container>
    </app-loading>
  </ng-container>
</app-loading>