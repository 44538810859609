import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestDetailService } from './request-detail.service';
import { ToastService } from 'src/shared-services/toast.service';
import { RequestService } from '../my-requests/request.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { RequestDetailFeasibilityService } from './request-detail-feasibility/request-detail-feasibility.service';

@Component({
	selector: 'app-request-detail',
	standalone: false,
	templateUrl: './request-detail.component.html'
})
export class RequestDetailComponent implements OnInit, OnDestroy {

	public items = [];
	public newRequestStatus: {
		title: string, step: number, requestLastStatus?: string
	} = { title: 'New request', step: 1 };

	public isBelenderSidebarVisible = false;
	public belenderStatus: {
		actualStatus: string;
		actualStatusMessage: string;
	} = { actualStatus: '', actualStatusMessage: '' };
	public consentBelenderUrl: SafeResourceUrl;

	constructor(
		public requestDetailService: RequestDetailService,
		private toastService: ToastService,
		private requestService: RequestService,
		private sanitizer: DomSanitizer,
		private translate: TranslateService,
		private confirmationService: ConfirmationService,
		private requestDetailFeasibilityService: RequestDetailFeasibilityService) { }

	async ngOnInit() {
		await this.requestDetailService.getRequestFromUrl();
	}

	ngOnDestroy() {
		// reset the feasibility when the user navigates away from the request detail page
		this.requestDetailFeasibilityService.resetFeasibility();
		this.requestDetailService.resetFeasibilityData();
	}

	async reopenBelenderWidget() {
		try {
			this.requestDetailService.isLoading = true;
			this.newRequestStatus = {
				title: "Continuar con el OTP",
				step: 3,
				requestLastStatus: this.requestDetailService.request.personQuery.substatus
			};
			this.belenderStatus = {
				actualStatus: this.requestDetailService.request.personQuery.substatus,
				actualStatusMessage: this.translate.instant(this.requestService.getRequestSubstatusTitle(this.requestDetailService.request.personQuery.substatus))
			};
			// TODO: Arreglar esta ñapa
			this.consentBelenderUrl =
				this.sanitizer.bypassSecurityTrustResourceUrl(
					(await this.requestService.getAllRequests()).requestList.find(x => x.queryId === this.requestDetailService.queryId).consentUrl);

			this.isBelenderSidebarVisible = true;
		} catch (e) {
			console.log(e);
			this.toastService.showError(`Error ${e.status} (${e.statusText})`, `${e.error.detail}`, false);
		} finally {
			this.requestDetailService.isLoading = false;
		}
	}

	async cancelRequest() {
		// Llamar al servicio
		// redirigir a my-requests
		try {
			this.requestDetailService.isLoading = true;

			const deleteResponse = await this.requestDetailService.cancelRequest({
				queryId: this.requestDetailService.queryId,
				requestReference: undefined,
				userName: undefined,
				userPassword: undefined
			});

			if (deleteResponse.status !== 'Canceled')
				this.toastService.showError('TOAST.ERROR', `${deleteResponse.status}`, false, 10000);
			else {
				this.toastService.showSuccess('TOAST.SUCCESS', 'NEW_REQUEST.CANCEL', true, 10000);
				await this.requestDetailService.getRequestFromUrl();
				//this.router.navigate['home/my-requests'];
			}
		} catch (e) {
			this.toastService.showError(`Error ${e.error.status} (${e.error.title})`, `${e.error.detail}`, false);
		} finally {
			this.requestDetailService.isLoading = false;
		}
	}

	confirmCancelRequest(event: Event) {
		if (!event || !event.target) return;

		this.confirmationService.confirm({
			target: event.target,
			message: this.translate.instant('REQUEST_DETAIL.CANCEL_REQUEST_CONFIRMATION'),
			acceptLabel: this.translate.instant('BUTTON.YES'),
			rejectLabel: this.translate.instant('BUTTON.NO'),
			accept: () => {
				this.cancelRequest();
			}
		});
	}

	verifyRequestCanBeReopened(): boolean {
		if (!this.requestDetailService.request) return false;
		//const requestsThatCanBeReopened: string[] = ['created', 'terms_and_conditions', 'waiting_login_seguridad_social', 'saved_otp'];
		const requestsThatCanBeReopened: string[] = ['created', 'waiting_login_seguridad_social', 'waiting_login_aeat', 'waiting_otp_aeat', 'waiting_otp_seguridad_social'];
		return requestsThatCanBeReopened.includes(this.requestDetailService.request.personQuery.substatus);
	}

	hideCancelButton(): boolean {
		if (!this.requestDetailService.request) return true;
		return this.requestDetailService.request.personQuery.substatus == 'all_document_downloaded'
			|| this.requestDetailService.request.personQuery.status == 'Canceled'
			|| this.requestDetailService.request.personQuery.isDocumentsDowloadCompleted == true;
	}
}