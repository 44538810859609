<div class="request-steps-header">
    <ul>
        <li [ngClass]="{'current': newFeasibilityStatus.step === 1, 'done': newFeasibilityStatus.step > 1 }">
            <span>1</span>
            <p>{{ 'REQUEST_DETAIL.STEPS.ENTER_DATA' | translate }}</p>
        </li>
        <li [ngClass]="{'current': newFeasibilityStatus.step === 2, 'done': newFeasibilityStatus.step > 2 }">
            <span>2</span>
            <p>{{ 'REQUEST_DETAIL.STEPS.FEASIBILITY_RESULT' | translate }}</p>
        </li>
        <li [ngClass]="{'current': newFeasibilityStatus.step === 3, 'done': newFeasibilityStatus.step > 3 }">
            <span>3</span>
            <p>{{ 'REQUEST_DETAIL.STEPS.ACCEPT_DENY' | translate }}</p>
        </li>
    </ul>
</div>

<form [formGroup]="requestDetailFeasibilityService.feasibilityForm" *ngIf="this.newFeasibilityStatus.step === 1">
    <div class="grid viability-form">
        <div class="col-12 grid">
            <label for="IngresosElegibles" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.INGRESOS_ELEGIBLES' | translate }}
            </label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="IngresosElegibles"
                    formControlName="IngresosElegibles" placeholder="{{ 'COMMON.REQUIRED' | translate }}" />
            </div>
        </div>

        <div class="col-12 grid">
            <label for="GastosComprometidos" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.GASTOS_COMPROMETIDOS' | translate }}
            </label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="GastosComprometidos"
                    formControlName="GastosComprometidos" placeholder="{{ 'COMMON.REQUIRED' | translate }}" />
            </div>
        </div>

        <div class="col-12 grid">
            <label for="ScoreOpenBanking" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.SCORE_OPEN_BANKING' | translate }}
            </label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="ScoreOpenBanking"
                    formControlName="ScoreOpenBanking" placeholder="{{ 'COMMON.REQUIRED' | translate }}" />
            </div>
        </div>

        <div class="col-12 grid">
            <label for="CodigoReglaOB1" class="col-fixed">{{ 'FEASIBILITY_VARIABLES.CODE_RULE_OB1' | translate }}</label>
            <div class="col">
                <input pInputText id="CodigoReglaOB1" formControlName="CodigoReglaOB1">
            </div>
        </div>

        <div class="col-12 grid">
            <label for="CodigoReglaOB2" class="col-fixed">{{ 'FEASIBILITY_VARIABLES.CODE_RULE_OB2' | translate }}</label>
            <div class="col">
                <input pInputText id="CodigoReglaOB2" formControlName="CodigoReglaOB2">
            </div>
        </div>

        <div class="col-12 grid">
            <label for="ImporteDeudaTotalCirculanteCIRBE" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.IMPORTE_DEUDA_TOTAL_CIRCULANTE' | translate }}
            </label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="ImporteDeudaTotalCirculanteCIRBE"
                    formControlName="ImporteDeudaTotalCirculanteCIRBE" placeholder="{{ 'COMMON.REQUIRED' | translate }}" />
            </div>
        </div>

        <div class="col-12 grid">
            <label for="ImporteMoraCIRBE" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.IMPORTE_MORA_CIRBE' | translate }}
            </label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="ImporteMoraCIRBE"
                    formControlName="ImporteMoraCIRBE" placeholder="{{ 'COMMON.REQUIRED' | translate }}" />
            </div>
        </div>

        <!-- <div class="col-12 grid">
            <label for="DescubiertosCuenta" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.DESCUBIERTOS_CUENTA' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="DescubiertosCuenta"
                    formControlName="DescubiertosCuenta"></p-inputNumber>
            </div>
        </div>

        <div class="col-12 grid">
            <label for="IngresosRecurrentesTrimestrales" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.INGRESOS_RECURRENTES_TRIMESTRALES' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="IngresosRecurrentesTrimestrales"
                    formControlName="IngresosRecurrentesTrimestrales"></p-inputNumber>
            </div>
        </div> -->

        <!-- <div class="col-12 grid">
            <label for="NumeroRecobrosUltimoAno" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.NUMERO_RECOBROS_ULTIMO_ANO' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="NumeroRecobrosUltimoAno"
                    formControlName="NumeroRecobrosUltimoAno"></p-inputNumber>
            </div>
        </div>

        <div class="col-12 grid">
            <label for="ImporteMedioGastosApuestas" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.IMPORTE_MEDIO_GASTOS_APUESTAS' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="ImporteMedioGastosApuestas"
                    formControlName="ImporteMedioGastosApuestas"></p-inputNumber>
            </div>
        </div>

        <div class="col-12 grid">
            <label for="CantidadMesesGastosApuestas" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.IMPORTE_MEDIO_TRANSACCIONES_APUESTAS' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="CantidadMesesGastosApuestas"
                    formControlName="CantidadMesesGastosApuestas"></p-inputNumber>
            </div>
        </div> -->

        <!-- <div class="col-12 grid">
            <label for="VariacionCaidaIngresos" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.VARIACION_CAIDA_INGRESOS' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="VariacionCaidaIngresos"
                    formControlName="VariacionCaidaIngresos"></p-inputNumber>
            </div>
        </div>

        <div class="col-12 grid">
            <label for="NumeroPagosPorFinancierasMensualmente" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.PAGOS_ENTIDADES_FINANCIERAS' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="NumeroPagosPorFinancierasMensualmente"
                    formControlName="NumeroPagosPorFinancierasMensualmente"></p-inputNumber>
            </div>
        </div>

        <div class="col-12 grid">
            <label for="SaldoMedioMinimoMensual6Meses" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.SALDO_MEDIO_MINIMO_MENSUAL' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="SaldoMedioMinimoMensual6Meses"
                    formControlName="SaldoMedioMinimoMensual6Meses"></p-inputNumber>
            </div>
        </div> -->

        <!-- <div class="col-12 grid">
            <label for="LimiteCreditoAsignar" class="col-fixed">{{
                'FEASIBILITY_VARIABLES.LIMITE_CREDITO_ASIGNAR' | translate }}</label>
            <div class="col">
                <p-inputNumber locale="es-ES" [maxFractionDigits]="2" inputId="LimiteCreditoAsignar"
                    formControlName="LimiteCreditoAsignar"></p-inputNumber>
            </div>
        </div>
        -->

        <div class="buttons-row">
            <button class="outline-btn" (click)="requestDetailFeasibilityService.resetFeasibility()">
                {{ "REQUEST_DETAIL.RESET" | translate }}
            </button>
            <button (click)="submit()" class="filled-btn">
                {{ 'REQUEST_DETAIL.CALCULATE' | translate }}
            </button>
        </div>
    </div>
</form>



<div *ngIf="requestDetailFeasibilityService.isFeasible !== undefined && this.newFeasibilityStatus.step === 2">
    <br />
    <table>
        <thead>
            <tr>
                <th>#</th>
                <th>{{ 'REQUEST_DETAIL.CONCEPT' | translate }}</th>
                <th>{{ 'REQUEST_DETAIL.RULE' | translate }}</th>
                <th>{{ 'REQUEST_DETAIL.STATUS' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let r of requestDetailFeasibilityService.ruleFeasibilityResults; let i = index"
                [ngClass]="{ 'not-feasible': !r.isFeasible, 'feasible': r.isFeasible && !OBRules.includes(r.fieldName) }">
                <td>{{ i + 1 }}</td>
                <!-- <td>{{ translateRuleConcept(r.translationLabel) | async }}</td>
                <td>{{ translateRuleDescription(r.translationLabel) | async }}</td> -->
                <td>{{ r.ruleConcept }}</td>
                <td>
                    @if(r.ruleDescription === ''){
                        <span class="material-symbols-outlined">
                            remove
                        </span>
                    }@else {
                        {{ r.ruleDescription }}
                    }
                </td>
                <td class="center">
                    @if(OBRules.includes(r.fieldName) && r.isFeasible){
                        <span class="material-symbols-outlined">
                            remove
                        </span>
                    }@else {
                        <span class="line-result" [class.pass]="r.isFeasible">
                            <span class="material-symbols-outlined">
                                {{ r.isFeasible ? 'check_small' : 'exclamation' }}
                            </span>
                        </span>
                    }
                </td>
            </tr>
        </tbody>
    </table>
    <br><br>

    <div class="buttons-row">
        <button class="outline-btn" (click)="goToStepOne()">{{ "NEW_REQUEST.BACK" | translate }}</button>
        <button (click)="goToAcceptOrDenied()" *ngIf="requestDetailFeasibilityService.isFeasible === false"
            class="filled-btn">{{ 'BUTTON.NEXT' | translate }}</button>
        <button (click)="goToAcceptOrDenied()" *ngIf="requestDetailFeasibilityService.isFeasible === true"
            class="filled-btn">{{ 'BUTTON.NEXT' | translate }}</button>
    </div>
</div>

<div *ngIf="requestDetailFeasibilityService.isFeasible !== undefined && this.newFeasibilityStatus.step === 3">
    <br />
    <div *ngIf="requestDetailFeasibilityService.isFeasible === true">
        <div class="result-box">
            @if(requestDetailFeasibilityService.isValidCreditValue){
            <img src="assets/images/credit-approved.svg" alt="Feasibility approved" />
            }@else{
            <img src="assets/images/credit-denied.svg" alt="Feasibility denied" />
            }
            <div>
                @if(requestDetailFeasibilityService.isValidCreditValue){
                <h6>{{ 'REQUEST_DETAIL.PREAPPROVED_CREDIT.TITLE' | translate }}</h6>
                <p [innerHTML]="'REQUEST_DETAIL.PREAPPROVED_CREDIT.DESCRIPTION' | translate"></p>
                }@else if (requestDetailFeasibilityService.isRCOB003RuleValid) {
                <h6>{{ 'REQUEST_DETAIL.DENIED_CREDIT.TITLE' | translate }}</h6>
                <p>{{ 'REQUEST_DETAIL.INVALID_AMOUNT' | translate }}</p>
                }@else {
                <h6>{{ 'REQUEST_DETAIL.DENIED_CREDIT.TITLE' | translate }}</h6>
                {{ 'REQUEST_DETAIL.INVALID_RCOB003' | translate: { value1:
                requestDetailFeasibilityService.feasibilityForm.get('IngresosRecurrentesTrimestrales').value, value2:
                (requestDetailFeasibilityService.credit * 0.3)} }}
                }
                @if(requestDetailFeasibilityService.isRCOB003RuleValid){
                <div class="grid">
                    <div class="col-4">
                        <label for="reason">{{ 'REQUEST_DETAIL.AMOUNT' | translate }}</label>
                        <p-inputNumber [disabled]="!requestDetailFeasibilityService.isValidCreditValue" locale="es-ES"
                            [maxFractionDigits]="2" inputId="reason"
                            [(ngModel)]="requestDetailFeasibilityService.credit" />
                    </div>
                    <div>
                        <p>{{ 'REQUEST_DETAIL.MIN_AMOUNT' | translate:{amount:
                            requestDetailFeasibilityService.minCreditLimit} }}</p>
                        <p>{{ 'REQUEST_DETAIL.MAX_AMOUNT' | translate:{amount:
                            requestDetailFeasibilityService.maxCreditLimit} }}</p>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>

    <div *ngIf="requestDetailFeasibilityService.isFeasible === false">
        <div class="result-box">
            <img src="assets/images/credit-denied.svg" alt="Credit denied" />
            <div>
                <h6>{{ 'REQUEST_DETAIL.DENIED_CREDIT.TITLE' | translate }}</h6>
                <p [innerHTML]="'REQUEST_DETAIL.DENIED_CREDIT.DESCRIPTION' | translate"></p>
            </div>
        </div>
    </div>

    <div class="buttons-row">
        <button class="outline-btn" (click)="goToStepTwo()">{{ "NEW_REQUEST.BACK" | translate }}</button>
        <button (click)="completeOperation()"
            *ngIf="requestDetailFeasibilityService.isFeasible === false || requestDetailFeasibilityService.isRCOB003RuleValid === false"
            class="filled-btn">{{ 'BUTTON.NEXT' | translate }}</button>
        <button (click)="completeOperation()"
            *ngIf="requestDetailFeasibilityService.isFeasible === true && requestDetailFeasibilityService.isValidCreditValue"
            [disabled]="!requestDetailFeasibilityService.isCreditBetweenLimits()" class="filled-btn">{{
            'BUTTON.NEXT' | translate }}</button>
    </div>
</div>