import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RequestService } from './request.service';
import { FilterResponse, PersonQuery } from 'src/app/models/FilterResponse';
import { BehaviorSubject, Subject, debounceTime, tap } from 'rxjs';
import { ToastService } from 'src/shared-services/toast.service';
import { RowsPerPageDefault, RowsPerPageOptions } from 'src/shared/constants/common.constants';

@Injectable({
	providedIn: 'root'
})
export class RequestsFilterService {

	public form: FormGroup;
	public isLoading = true;
	public searchingByColumnFilter = false;
	public lastSearchTime: Date;
	private notificationSubject = new Subject<FilterResponse>();
	public globalSearchSubject = new Subject<{ inputValue: string, inputFallBackReference: HTMLInputElement }>();
	private lastInputReference: HTMLInputElement;

	public rowsPerPage = RowsPerPageDefault;
	public rowsPerPageOptions = RowsPerPageOptions;

	public sortField = new BehaviorSubject<string>('');
	public sortOrder = new BehaviorSubject<number>(1);

	private cachedFilterResponse: FilterResponse;
	public query = '';

	constructor(private fb: FormBuilder,
		private requestService: RequestService,
		private toastService: ToastService) {
		this.form = this.fb.group({
			queryId: [''],
			queryType: [''],
			personId: [''],
			firstName: [''],
			email: [''],
			phoneNumber: [''],
			personType: [''],
			startDate: [''],
			endDate: [''],
			status: []
		}, { validators: this.datesValidator('startDate', 'endDate') });

		// Subject para las busquedas globales
		this.globalSearchSubject.pipe(
			tap(() => this.isLoading = true),
			debounceTime(1000) // Espera 1000ms después de que el usuario deje de escribir
		).subscribe((input) => {
			this.globalSearch(input.inputValue);
			this.lastInputReference = input.inputFallBackReference;
		});

		// debounceTime propaga que se ha activado algun filtro de nuestro formulario.
		for (const key of Object.keys(this.form.controls))
			this.form.get(key)?.valueChanges.pipe(
				tap(() => this.isLoading = true),
				debounceTime(300)
			).subscribe(() => this.search());
	}

	async search(forceGetAllRequests?: boolean) {
		let filterOutput: FilterResponse;
		if (this.form.valid) {
			this.isLoading = true;
			try {
				await this.getDefaultFilterResponse(forceGetAllRequests);
				filterOutput = await this.filterRequests(this.form);
				this.notificationSubject.next(filterOutput);
			} catch (e) {
				console.log(e);
				this.toastService.showError(`Error ${e.status} (${e.statusText})`, `${e.message}`, false);
			}
		}
		else {
			this.toastService.showError('TOAST.INVALID', 'FILTER.FILTER_FORM_INVALID', true);
		}
		this.isLoading = false;
		this.searchingByColumnFilter = false;
	}

	private async getDefaultFilterResponse(forceGetAllRequests?: boolean) {
		if (forceGetAllRequests || this.cachedFilterResponse == null) {
			this.cachedFilterResponse = await this.requestService.getAllRequests();
			this.lastSearchTime = new Date();
		}
	}

	public async filterRequests(form: FormGroup): Promise<FilterResponse> {
		// aquí ira la query con el filtro
		//return await this.api.post<FilterResponse>(`requests/filter-requests`, {});
		return this.clientSideFilter(form);
	}

	public async clientSideFilter(form: FormGroup): Promise<FilterResponse> {

		let filteredPersonQueries: PersonQuery[] = [];

		for (const personQuery of this.cachedFilterResponse.requestList) {

			const fullName = `${personQuery.firstName} ${personQuery.firstSurname} ${personQuery.secondSurname}`; // we need the full name to filter by it.

			if (
				this.evaluateStringFormControl(form.get('queryId'), personQuery.queryId) &&
				// this.evaluateStringFormControl(form.get('queryType'), personQuery.queryType) &&	// TODO: deccoment this line when the API is ready
				// this.evaluateStringFormControl(form.get('personId'), personQuery.personId) &&	// TODO: deccoment this line when the API is ready
				this.evaluateStringFormControl(form.get('firstName'), fullName) &&
				this.evaluateStringFormControl(form.get('email'), personQuery.email) &&
				this.evaluateStringFormControl(form.get('phoneNumber'), personQuery.phoneNumber) &&
				this.evaluateStringFormControl(form.get('personType'), personQuery.personType) &&
				// TODO: ARREGAR ÑAPA ESTADOS 2.0
				//this.evaluateStringFormControl(form.get('status'), personQuery.status) &&
				this.evaluateStringFormControl(form.get('status'), this.requestService.getRequestStatusTitleFromSubStatus(personQuery.substatus)) &&
				this.evaluateDateFormControl(form.get('startDate'), form.get('endDate'), personQuery)
			) {
				filteredPersonQueries.push(personQuery);
			}
		}

		// Ejecutamos la subquery del filtro si tiene valor.
		if (this?.query != null || this?.query != '') {
			filteredPersonQueries = this.searchResultsFilter(filteredPersonQueries);
		}

		return {
			requestList: filteredPersonQueries,
			requestPerPage: 0,
			fromIndex: 0,
			toIndex: 0,
			pageNumber: 0,
		};
	}

	private searchResultsFilter(inputRequests: PersonQuery[]): PersonQuery[] {
		const filteredPersonQueries: PersonQuery[] = [];

		// En vez de hacer un bloque condicionantes de if()...
		// se recorren todas las propiedades del objeto y se comprueban con la query.
		for (const request of inputRequests) {
			let shouldBeInserted = false;
			for (const key in request) {
				if (Object.prototype.hasOwnProperty.call(request, key)) {
					if ((request[key].toString()).toLowerCase().includes(this.query.toLocaleLowerCase())) {
						shouldBeInserted = true;
						break;
					}
				}
			}
			if (shouldBeInserted) filteredPersonQueries.push(request);
		}

		return filteredPersonQueries;
	}

	// Busca en los resultados actuales. Es compatible con los filtros.
	async globalSearch(query: string) {
		this.isLoading = true;

		this.query = query;
		const filterOutput = await this.filterRequests(this.form);

		this.notificationSubject.next(filterOutput);
		this.isLoading = false;
	}

	filterHandler() {
		return this.notificationSubject.asObservable();
	}

	datesValidator(startDate: string, endDate: string): ValidatorFn {
		return (formGroup: AbstractControl): ValidationErrors | null => {
			const start = formGroup.get(startDate)?.value;
			const end = formGroup.get(endDate)?.value;

			if (!start || !end) {
				return null;
			}
			return new Date(start) <= new Date(end) ? null : { datesValidator: true };
		};
	}


	// Devolver si el campo cumple con la condicion del filtro.
	private evaluateStringFormControl(formControl: AbstractControl, valueToCheck: string): boolean {
		if (formControl.value != null && formControl.value != '') {
			return valueToCheck.toLowerCase().includes((formControl.value as string).toLocaleLowerCase());
		}
		else {
			return true;
		}
	}

	// private evaluateMultipleStringsFormControl(formControl: AbstractControl, valuesToCheck: string[]): boolean {
	//   if (formControl.value != null && formControl.value != '') {
	//     return valuesToCheck.some(valueFromPersonQuery => valueFromPersonQuery?.toLocaleLowerCase().includes((formControl.value.toString()).toLocaleLowerCase()));
	//   }
	//   else {
	//     return true;
	//   }
	// }

	private evaluateDateFormControl(fromDateForm: AbstractControl, toDateForm: AbstractControl, personQuery: PersonQuery) {
		if (
			fromDateForm.value != null && fromDateForm.value != '' &&
			toDateForm.value != null && toDateForm.value != ''
		) {
			const from = new Date(fromDateForm.value);
			const to = new Date(toDateForm.value);
			const createdDateTime = new Date(personQuery.createdDateTime);

			return from <= createdDateTime && createdDateTime <= to;
		}
		else if (fromDateForm.value != null && fromDateForm.value != '') {
			const from = new Date(fromDateForm.value);
			return from <= new Date(personQuery.createdDateTime);
		}
		else if (toDateForm.value != null && toDateForm.value != '') {
			const to = new Date(toDateForm.value);
			return new Date(personQuery.createdDateTime) >= to;
		} else
			return true;
	}

	//TODO: ARREGLAR ÑAPA ESTADOS 6.0 
	public getStatusCount(status: string) {
		if (!this.cachedFilterResponse)
			return 0;
		else
			return this.cachedFilterResponse.requestList.filter((x) => this.requestService.getRequestStatusTitleFromSubStatus(x.substatus) === status).length;
	}

	public checkActiveStatus(status: string) {
		return this.form.get('status').value === status;
	}


	// CLEANING FILTERS AND GLOBAL SEARCH \\

	clearFilter(key: string) {
		this.form.controls[key].setValue('');
	}

	clearAllFilters() {
		const formControls = this.form.controls;
		const formKeys = Object.keys(formControls);

		formKeys.forEach(key => {
			formControls[key].setValue('');
		});
	}

	clearGlobalSearch() {
		if (this.lastInputReference) this.lastInputReference.value = '';
		this.query = '';
		this.search();
	}

	clearAllFiltersAndGlobalSearch() {
		this.rowsPerPage = RowsPerPageDefault;
		this.clearAllFilters();
		this.clearGlobalSearch();
	}

	setSortField(field: string, order: number) {
		this.sortField.next(field);
		this.sortOrder.next(order);
	}

	resetSortField() {
		this.sortField.next('');
		this.sortOrder.next(1);
	}
}